import { styled, Typography, Toolbar } from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
    marginBottom: `50px`,
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
}));

function AppTitle() {
    return (
        <AppBar position="fixed">
            <Toolbar>
                <Typography variant="h6" noWrap component="div">
                    Football Analytics
                </Typography>
            </Toolbar>
        </AppBar>
    );
}

export default AppTitle;
