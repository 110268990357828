import { useState, useEffect, Fragment } from "react";
import { createClient, Session, User } from "@supabase/supabase-js";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Button, Grid } from "@mui/material";
import AppTitle from "./components/AppTitle";
import { useRecoilState } from "recoil";
import {
    seasonIdState,
    teamIdState,
    compIdState,
    uiFrameWindowState,
    teamsPlayersState,
} from "./recoil/atoms";
import PageStarter from "./pages/PageStarter";
import PageTeamSelector from "./pages/PageTeamSelector";
import { supabaseCreds } from "./utils/supabaseCreds";
import PageTeamDisplay from "./pages/PageTeamDisplay";

const supabase = createClient(supabaseCreds.supabaseUrl, supabaseCreds.token);

export default function Overview2() {
    const [session, setSession] = useState<Session | null>(null);
    const [user, setUser] = useState<User | null>();
    const [uiFrameWindow, setUiFrameWindow] =
        useRecoilState(uiFrameWindowState);
    const [teamId, setTeamId] = useRecoilState(teamIdState);
    const [compId, setCompId] = useRecoilState(compIdState);
    const [seasonId, setSeasonId] = useRecoilState(seasonIdState);
    const [teamsPlayers, setTeamsPlayers] =
        useRecoilState<any>(teamsPlayersState);

    async function logoutSupabase() {
        await supabase.auth.signOut();
    }

    function clearTeam() {
        setCompId(0);
        setSeasonId(0);
        setTeamId(0);
        setTeamsPlayers([]);
        setUiFrameWindow("seasonAndMatchSelector");
    }

    useEffect(() => {
        setUiFrameWindow("seasonAndMatchSelector");

        supabase.auth.getSession().then(({ data: { session } }) => {
            setSession(session);
        });

        const {
            data: { subscription },
        } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
        });

        (async () => {
            const {
                data: { user },
            } = await supabase.auth.getUser();
            setUser(user);
        })();

        return () => subscription.unsubscribe();
    }, []);

    if (!session) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Auth
                        supabaseClient={supabase}
                        appearance={{ theme: ThemeSupa }}
                        showLinks={false}
                        providers={[]}
                    />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}></Grid>
            </Grid>
        );
    } else {
        console.log("DEBUG LOTS");
        return (
            <Fragment>
                <AppTitle></AppTitle>
                <hr />
                <hr />
                <hr />
                <hr />
                <hr />
                <hr />
                <hr />
                {uiFrameWindow === "seasonAndMatchSelector"
                    ? (console.log("DEBUG aaaaa"), (<PageStarter />))
                    : null}
                {uiFrameWindow === "teamSelector" ? <PageTeamSelector /> : null}
                {uiFrameWindow === "teamDisplay" ? <PageTeamDisplay /> : null}
                <hr />
                <div>Logged In As... {user?.email}</div>
                <div>LOGGED IN!</div>
                <Button onClick={() => clearTeam()}>Clear selected team</Button>
                <Button onClick={() => logoutSupabase()}>LOG OUT!</Button>
            </Fragment>
        );
    }
}
