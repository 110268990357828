import { createClient } from "@supabase/supabase-js";
import { useEffect } from "react";
import { Fragment } from "react/jsx-runtime";
import { useRecoilState, useRecoilValue } from "recoil";
import { Buffer } from "buffer";
import {
    compIdState,
    compSeasonNameState,
    seasonIdState,
    seasonsTeamsState,
    teamIdState,
    uiFrameWindowState,
} from "../recoil/atoms";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Typography,
    Grid,
} from "@mui/material";

const supabase = createClient(
    "https://kuvbqyqwemojhegohovd.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt1dmJxeXF3ZW1vamhlZ29ob3ZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUzMDg1OTEsImV4cCI6MjA0MDg4NDU5MX0.qw2bgkH3qP85d9qv5npQG-EtPDAC8eLtB-LOharq8DA",
);

function PageTeamSelector() {
    const compId = useRecoilValue(compIdState);
    const seasonId = useRecoilValue(seasonIdState);
    const seasonName = useRecoilValue(compSeasonNameState);
    const [seasonsTeams, setSeasonsTeams] =
        useRecoilState<any>(seasonsTeamsState);
    const [uiFrameWindow, setUiFrameWindow] =
        useRecoilState(uiFrameWindowState);
    const [teamId, setTeamId] = useRecoilState(teamIdState);

    // Used for reading file from storage
    useEffect(() => {
        (async () => {
            try {
                //ts-ignore
                const { data } = await supabase.storage
                    .from("sop-wy-data")
                    .download(
                        compId + "-" + seasonId + "/02_season_teams_data.json",
                    );
                // .download("364-189908/02_season_teams_data.json");
                const blob = data;
                const buffer = Buffer.from(await blob!.arrayBuffer());
                setSeasonsTeams(JSON.parse(buffer.toString())["teams"]);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        })();
    }, []);

    function selectTeam(team_id: number) {
        setTeamId(team_id);
        setUiFrameWindow("teamDisplay");
    }

    return (
        <Fragment>
            Team Selector for Season {seasonName} - <hr />
            <Grid container spacing={2}>
                {seasonsTeams !== null ? (
                    seasonsTeams.map(
                        (team: any) =>
                            (
                                // <Button>
                                //     {/* {seas.compName} - {seas.seasonName} */}
                                //     {seas.comp_season_name}
                                // </Button>
                                console.log("DEBUG PageTeamSelector"),
                                (
                                    // <Button>
                                    //     {team.name} - {team.wyId} -{" "}
                                    //     {team.imageDataURL}
                                    // </Button>
                                    <Grid item xs={1}>
                                        {/* <Box sx={{ minWidth: 275 }}> */}
                                        <Box>
                                            <Card variant="outlined">
                                                <CardContent>
                                                    <CardMedia
                                                        sx={{
                                                            height: 100,
                                                            width: 100,
                                                        }}
                                                        // image="/static/images/cards/contemplative-reptile.jpg"
                                                        image={
                                                            team.imageDataURL
                                                        }
                                                        title={team.name}
                                                    />
                                                    <Typography
                                                        variant="h5"
                                                        component="div"
                                                    >
                                                        {team.name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            color: "text.secondary",
                                                            mb: 1.5,
                                                        }}
                                                    >
                                                        {team.wyId}
                                                    </Typography>
                                                    {/* <Typography variant="body2">
                                                        well meaning and kindly.
                                                        <br />
                                                        {'"a benevolent smile"'}
                                                    </Typography> */}
                                                </CardContent>
                                                <CardActions>
                                                    <Button
                                                        onClick={() =>
                                                            selectTeam(
                                                                team.wyId,
                                                            )
                                                        }
                                                        size="small"
                                                    >
                                                        More
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Box>
                                    </Grid>
                                )
                            ),
                    )
                ) : (
                    <Fragment>AFTER</Fragment>
                )}
            </Grid>
        </Fragment>
    );
}

export default PageTeamSelector;
