import { useEffect, useMemo } from "react";
import { Fragment } from "react/jsx-runtime";
import { createClient } from "@supabase/supabase-js";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    compIdState,
    seasonIdState,
    teamIdState,
    teamsPlayersState,
} from "../recoil/atoms";
import { Buffer } from "buffer";
import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";

const supabase = createClient(
    "https://kuvbqyqwemojhegohovd.supabase.co",
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt1dmJxeXF3ZW1vamhlZ29ob3ZkIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUzMDg1OTEsImV4cCI6MjA0MDg4NDU5MX0.qw2bgkH3qP85d9qv5npQG-EtPDAC8eLtB-LOharq8DA",
);

function PageTeamDisplay() {
    const compId = useRecoilValue(compIdState);
    const seasonId = useRecoilValue(seasonIdState);
    const teamId = useRecoilValue(teamIdState);
    const [teamsPlayers, setTeamsPlayers] =
        useRecoilState<any>(teamsPlayersState);

    const tabs = useMemo(
        () => [
            {
                label: "Players List",
                content: teamsPlayers ? (
                    <Grid container spacing={2}>
                        {teamsPlayers.map((player: any) => (
                            <Grid item xs={1}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography
                                            variant="h5"
                                            component="div"
                                        >
                                            {player?.shortName} ({player?.wyId})
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small">More</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <p>Loading...</p>
                ),
            },
            {
                label: "Fixture list",
                content: <Fragment>Fixture List Placeholder</Fragment>,
            },
            {
                label: "Scores",
                content: <Fragment>Scores Placeholder</Fragment>,
            },
        ],
        [teamsPlayers],
    );

    useEffect(() => {
        const fetchTeamsPlayers = async () => {
            try {
                const { data } = await supabase.storage
                    .from("sop-wy-data")
                    .download(
                        `${compId}-${seasonId}/05_team_${teamId}_players_data.json`,
                    );
                if (data === null) {
                    console.error("Error loading data: data is null");
                    return;
                }
                const blob = data;
                const buffer = Buffer.from(await blob!.arrayBuffer());
                setTeamsPlayers(JSON.parse(buffer.toString())?.["squad"]);
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
        fetchTeamsPlayers();
    }, [teamId, compId, seasonId]);

    return (
        <Tabs aria-label="Basic tabs" defaultValue={0}>
            {/* The TabList component contains a list of the tabs. */}
            <TabList
                disableUnderline
                sx={{
                    p: 0.5,
                    gap: 0.5,
                    borderRadius: "xl",
                    bgcolor: "background.level1",
                    "& .MuiTab-root.Mui-selected": {
                        boxShadow: "sm",
                        bgcolor: "background.surface",
                    },
                    "--Tab-indicatorSize": "30px",
                }}
            >
                {/* Map over the tabs array and render a Tab component for each tab. */}
                {tabs.map((tab) => (
                    <Tab key={tab.label}>{tab.label}</Tab>
                ))}
            </TabList>
            {/* Map over the tabs array and render a TabPanel component for each tab. */}
            {tabs.map((tab) => (
                <TabPanel value={tabs.indexOf(tab)} key={tab.label}>
                    {tab.content}
                </TabPanel>
            ))}
        </Tabs>
    );
}
export default PageTeamDisplay;
