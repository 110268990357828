import { useEffect, useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { Button } from "@mui/material";
// import { Buffer } from "buffer";
import { createClient } from "@supabase/supabase-js";
import { Pitchy } from "@andrewfooteqa/sop-pitchy-ts-lib";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    compIdState,
    compSeasonNameState,
    seasonIdState,
    uiFrameWindowState,
} from "../recoil/atoms";
import { supabaseCreds } from "../utils/supabaseCreds";

function PageStarter() {
    console.log("DEBUG AA");

    // const [basicData, setBasicData] = useState<[] | null>(null); // Used from file from storage
    const [seasonsAndComps, setSeasonsAndComps] = useState<any[] | null>([]);
    const [uiFrameWindow, setUiFrameWindow] =
        useRecoilState(uiFrameWindowState);
    const [compId, setCompId] = useRecoilState(compIdState);
    const [seasonId, setSeasonId] = useRecoilState(seasonIdState);
    const [compSeasonName, setCompSeasonName] =
        useRecoilState(compSeasonNameState);

    useEffect(() => {
        const supabase = createClient(
            supabaseCreds.supabaseUrl,
            supabaseCreds.token,
            { db: { schema: "sop-test1" } },
        );
        (async () => {
            const { data } = await supabase
                .schema("sop-test1")
                .from("comps_seasons")
                .select("*");
            setSeasonsAndComps(data);
        })();
    }, []);

    function selectCompSeason(
        comp_id: number,
        season_id: number,
        season_name: string,
    ) {
        setCompId(comp_id);
        setSeasonId(season_id);
        setCompSeasonName(season_name);
        setUiFrameWindow("teamSelector");
    }

    // Used for reading file from storage
    // useEffect(() => {
    //     (async () => {
    //         try {
    //             //ts-ignore
    //             const { data } = await supabase.storage
    //                 .from("sop-wy-data")
    //                 .download("seasons.json");
    //             const blob = data;
    //             const buffer = Buffer.from(await blob!.arrayBuffer());
    //             console.log("DEBUG: data 1 " + buffer);
    //             setBasicData(JSON.parse(buffer.toString()));
    //         } catch (error) {
    //             console.error("Error loading data:", error);
    //         }
    //     })();
    // }, []);

    return (
        <Fragment>
            <div>Page - Entry Point</div>
            <Fragment>WAIT</Fragment>
            <Fragment>WAIT</Fragment>
            <Fragment>WAIT</Fragment>
            <p>{JSON.stringify(seasonsAndComps)}</p>
            {seasonsAndComps !== null && seasonsAndComps.length > 0 ? (
                (console.log("DEBUG XX: "),
                seasonsAndComps.map((seas: any) => (
                    <Button
                        onClick={() =>
                            selectCompSeason(
                                seas.comp_id,
                                seas.season_id,
                                seas.comp_season_name,
                            )
                        }
                    >
                        {seas.comp_season_name}
                    </Button>
                )))
            ) : (
                <Fragment>AFTER</Fragment>
            )}
        </Fragment>
    );
}

export default PageStarter;
