import React from "react";
import reportWebVitals from "./utils/reportWebVitals";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";
import Overview from "./Overview";
import { RecoilDevTools } from "recoil-toolkit";
import Overview2 from "./Overview2";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <RecoilRoot>
            <RecoilDevTools forceSerialize={false} />
            <Overview2 />
        </RecoilRoot>
    </React.StrictMode>,
);

reportWebVitals();
