import { atom } from "recoil";

export const uiFrameWindowState = atom({
    key: "uiFrameWindowState",
    default: "overview",
});

export const compIdState = atom({
    key: "compIdState",
    default: 0,
});

export const teamIdState = atom({
    key: "teamIdState",
    default: 0,
});

export const seasonIdState = atom({
    key: "seasonIdState",
    default: 0,
});

export const compSeasonNameState = atom({
    key: "compSeasonNameState",
    default: "",
});

export const seasonsTeamsState = atom({
    key: "seasonsTeamsState",
    default: [],
});

export const teamsPlayersState = atom({
    key: "teamsPlayersState",
    default: [],
});
